<template>
  <div class="container">
    <SecondNav></SecondNav>
    <RaisingNav></RaisingNav>
    <div class="list_content">
        <div>
            <h1>饲料配方</h1>
        </div>
        <div>
            <ul>
                <li v-for="(item,idx) in list" :key="idx">
                    <div class="flex">
                        <h1>饲料配方</h1>
                        <h2>{{item.title}}</h2>
                    </div>
                    <p>
                        <span>{{item.createTime}}</span>
                        <span class="segmentation">|</span>
                        <span>{{item.createUser}}</span>
                    </p>
                    <p class="ovflhide" v-html="item.content">{{item.content}}</p>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import { getColumnID, getColumnList } from '@/api/pigtechnology'
import SecondNav from '../../about/components/secondNav'
import RaisingNav from '../components/raisingNav.vue'
export default {
  components: {
    SecondNav,
    RaisingNav
  },
  data () {
    return {
      list: ''
    }
  },
  methods: {
    getcouList () {
      getColumnID({
        level: 3
      }).then((res) => {
        const cid = res.data.records[7].id
        getColumnList({
          columnId: cid
        }).then((res) => {
          this.list = res.data.records
        })
      })
    }
  },
  mounted () {
    this.getcouList()
  }
}
</script>

<style>
.list_content{
    text-align: left;
    margin-top: 30px;
}
.list_content>div:nth-child(1){
    border-bottom: 1px solid #dcdcdc;
}
.list_content>div:nth-child(1) h1{
    width: 200px;
    border-bottom: 3px solid #93b067;
    font-weight: bold;
    text-align: center;
    color: #93b067;
    padding-bottom: 8px;
}
.list_content>div:nth-child(2) li{
    padding: 20px 0;
    padding-left: 20px;
    margin:0 20px;
    border-bottom: 1px dashed #ddd;
}
.list_content>div:nth-child(2) div h1,h2{
    font-size: 20px;
    margin-left: 20px;
}
.list_content>div:nth-child(2) div h1{
    position: relative;
    color: #93b067;
    font-weight: bold;
}
.list_content>div:nth-child(2) div h1::before{
    content: "";
    width: 24px;
    height: 28px;
    position: absolute;
    background: url(../../../assets/img/u166.svg) no-repeat;
    left: -30px;
    top: 0;
}
.list_content>div:nth-child(2) p{
    margin-top: 10px;
    line-height: 25px;
}
.segmentation{
   margin: 0 10px;
}
.ovflhide{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>
